.grecaptcha-badge { 
  visibility: hidden;
}

.image-gallery-content:not(.fullscreen) .image-gallery-left-nav .image-gallery-svg, .image-gallery-content:not(.fullscreen) .image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px !important;
}

.image-gallery-content:not(.fullscreen) .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-content:not(.fullscreen) .image-gallery-play-button .image-gallery-svg {
  height: 24px !important;
  width: 24px !important;
}

.ui:not(.modal,.modals) .image-gallery-content:not(.fullscreen) .image-gallery-slide div {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 56.25% 0 0 0;
}
.ui:not(.modal,.modals) .image-gallery-content:not(.fullscreen) .image-gallery-slide div img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.image-gallery-content:not(.fullscreen) .image-gallery-thumbnail-image {
  width: auto;
  max-height: 52px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  overflow: hidden;
}

.ui.menu .item:before {
  width: 0px;
}

a.ui.card:hover {
  transform: none !important;
  box-shadow: 0 3px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.desktop-menu {
  display: inherit;
}
.mobile-menu {
  display: none;
  padding-left: 0.5rem;
}

.hero-module h1 {
  font-size: 3em!important;
  padding-top: 3em!important;
}
.hero-module h2 {
  font-size: 1.7em!important;
  margin-top: 1.5em!important;
}

.project-module h1, .project-module h2 {
  font-size: 3em!important;
}

.ui.segment.footer {
  padding: 5em 0em;
}

@media only screen and (min-width: 700px) {
  .pushable {
    transform: none !important;
  }
}
@media only screen and (max-width: 700px) {
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: inherit;
  }
  .hero-module h1 {
    font-size: 2em!important;
    padding-top: 1.5em!important;
  }
  .hero-module h2 {
    font-size: 1.5em!important;
    margin-top: 0.5em!important;
  }
  .project-module h1, .project-module h2 {
    font-size: 2em!important;
  }

  .project-module .button {
    margin-bottom: 1rem;
  }

  .ui.segment.footer {
    padding: 1em 0em;
    font-size: 1.1rem;
  }
  .footer .list .item {
    padding-bottom: .75rem;
  }
  .footer p a {
    line-height: 2rem;
  }
}